import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, ListSubheader } from '@material-ui/core';
import palette from '../.././theme/palette';
import {
  LatestSales
  // LatestOrders
} from './components';
import api from './../../services';
import {
  POLOS_UNINTA,
  CURSOS_UNINTA,
  PERIODOS_EAD
} from '../../variable/index';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  selectStyle: {
    height: '4em',
    width: '20em',

    marginLeft: 10
  },
  selectInChartStyle: {
    height: '2em',
    width: '10em'
  },
  formControl: { marginLeft: 12 },
  teste: { alignItems: 'center', backgroundColor: '#ff0' }
}));
const dataGeral = (
  dados,
  titulo = '',
  color = palette.primary.main,
  labels = PERIODOS_EAD
) => {
  return {
    labels,
    datasets: [
      {
        label: titulo,
        backgroundColor: 'transparent',
        borderColor: color,
        data: dados
      }
    ]
  };
};
const Dashboard = ({ history }) => {
  const classes = useStyles();
  const [stateSelect, setStateSelect] = useState('geral');
  const [stateSelectCurso, setStateSelectCurso] = useState(0);
  const [stateData, setStateData] = useState({
    dados: [],
    labels: [],
    percentDados: [],
    percentLabels: [],
    aprov: 0,
    reprov: 0,
    cursos: CURSOS_UNINTA
  });

  useEffect(() => {
    if (!sessionStorage.getItem('@aprovacoes/logged')) {
      history.push('/login');
    }
  }, []);

  const handleChangeSelect = async event => {
    event.preventDefault();
    const value = event.target.value;
    setStateSelect(value);

    const { cursos, dados, labels, percentDados, percentLabels } = stateData;
    cursos.length = 0;
    dados.length = 0;
    labels.length = 0;
    percentDados.length = 0;
    percentLabels.length = 0;
    // busca dados na api media
    const { data } = await api.post('/get-cursos-geral-polo', { polo: value });
    console.log(data);

    data.map(item => {
      cursos.push(item.curso);
    });

    setStateSelectCurso(0);
    //atualiza o estado
    setStateData({ ...stateData, cursos });
  };
  const handleChangeSelectCurso = async event => {
    event.preventDefault();
    const { value } = event.target;
    setStateSelectCurso(value);
    console.log(value);

    const { dados, labels, percentDados, percentLabels } = stateData;
    dados.length = 0;
    labels.length = 0;
    percentDados.length = 0;
    percentLabels.length = 0;
    // busca dados na api media
    const { data } = await api.post(
      stateSelect === 'geral' ? '/media-geral-curso' : '/media-polo-curso',
      { curso: value, polo: stateSelect }
    );
    console.log(data);

    data.map(item => {
      dados.push(new Number(item.media).toFixed(2));
      labels.push(item.periodo);
    });

    //busca dados na api porcentagem
    const dataPercent = await api.post(
      stateSelect === 'geral' ? '/percent-geral-curso' : '/percent-polo-curso',
      { curso: value, polo: stateSelect }
    );
    dataPercent.data.map(item => {
      percentDados.push(new Number(item.porcentos).toFixed(2));
      percentLabels.push(item.periodo);
    });

    //atualiza o estado
    setStateData({ ...stateData, dados, labels, percentDados, percentLabels });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Select
            className={classes.selectStyle}
            onChange={handleChangeSelect}
            value={stateSelect}
            variant="outlined">
            <MenuItem disabled value="geral">
              Geral
            </MenuItem>
            <ListSubheader disabled disableSticky>
              #Polos
            </ListSubheader>
            {POLOS_UNINTA.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <Select
            className={classes.selectStyle}
            onChange={handleChangeSelectCurso}
            value={stateSelectCurso}
            variant="outlined">
            <MenuItem disabled value={0}>
              Selecione o curso
            </MenuItem>
            {stateData.cursos.map(item => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <LatestSales
            data={dataGeral(
              stateData.dados,
              'Media de Aprovados',
              undefined,
              stateData.labels
            )}
            tableTitle="Media de Aprovados - G1-B"
          />
          {console.log(stateData.dados)}
        </Grid>

        <Grid item lg={6} md={6} xl={6} xs={12}>
          <LatestSales
            data={dataGeral(
              stateData.percentDados,
              '% aprovados',
              palette.secondary.main,
              stateData.percentLabels
            )}
            tableTitle="Porcentagem de aprovados G1-A"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
