import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Typography, Select, MenuItem, Grid } from '@material-ui/core/';

import api from './../../services';
import { PERIODOS_EAD } from '../../variable/index';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function createData(nomeCurso, percentagemAprovados, posicaoRanking) {
  return { nomeCurso, percentagemAprovados, posicaoRanking };
}

const useStyles = makeStyles({
  table: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '2em',
    paddingBottom: 100
  },
  colocacaoValues: { marginRight: 50 },
  header: {
    display: 'flex',
    flexDirection: 'column'
  },
  selectStyle: {
    height: '4em',
    width: '20em',
    marginBottom: 10,
    textAlign: 'left'
  }
});

const RankingCursos = ({ history }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    dados: [],
    rows: [],
    selected: 'geral'
  });

  useEffect(() => {
    if (!sessionStorage.getItem('@aprovacoes/logged')) {
      history.push('/login');
    }
  }, []);

  async function buscaDados(periodo) {
    const resposta =
      periodo === 'geral'
        ? await api.post('/ranking-curso')
        : await api.post('/ranking-curso-periodo', { periodo });
    let posicao = 0;
    const { rows } = state;
    rows.length = 0;
    resposta.data.forEach(item => {
      posicao++;
      rows.push(
        createData(item.curso, new Number(item.porcentos).toFixed(2), posicao)
      );
    });
    setState({ ...state, rows, selected: periodo });
  }
  useEffect(() => {
    buscaDados('geral');
  }, []);
  const handleChangeSelect = event => {
    const value = event.target.value;
    buscaDados(value);
  };
  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item>
          <Typography
            align="center"
            style={{ marginBottom: '1em', fontFamily: 'Open Sans' }}
            variant="h2">
            Ranking dos Cursos
          </Typography>
        </Grid>
        <Grid align="center" className={classes.teste} item xs={10}>
          <Select
            className={classes.selectStyle}
            onChange={handleChangeSelect}
            value={state.selected}
            variant="outlined">
            <MenuItem value="geral">Geral</MenuItem>
            {PERIODOS_EAD.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        style={{ display: 'flex', alignSelf: 'center', maxWidth: '90%' }}>
        <Table aria-label="customized table" className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome do Curso</StyledTableCell>
              <StyledTableCell align="center">
                Porcentagem de aprovados
              </StyledTableCell>
              <StyledTableCell align="right">Colocação</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.rows.map(row => (
              <StyledTableRow key={row.nomeCurso}>
                <StyledTableCell component="th" scope="row">
                  {row.nomeCurso}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.percentagemAprovados}%
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.posicaoRanking}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RankingCursos;
