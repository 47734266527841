import axios from 'axios';

export const __DEV__ = process.env.NODE_ENV === 'development';
const api = axios.create({
  baseURL: __DEV__
    ? 'http://localhost:3002/api/aprovacao'
    : 'https://analyticsacademico.aiamis.com.br/api/aprovacao'
  // : 'http://186.225.40.37/api/aprovacao'
});
export const apiLogin = axios.create({
  baseURL: __DEV__
    ? 'http://localhost:3002/api/aprovacao/login'
    : 'https://analyticsacademico.aiamis.com.br/api/aprovacao/login'
  // : 'http://186.225.40.37/api/aprovacao/login'
});

export default api;
