export const CURSOS_UNINTA = [
  'BACHARELADO EM ADMINISTRAÇÃO',
  'BACHARELADO EM BIOMEDICINA',
  'BACHARELADO EM CIÊNCIAS CONTÁBEIS',
  'BACHARELADO EM CIÊNCIAS ECONÔMICAS',
  'BACHARELADO EM EDUCAÇÃO FÍSICA',
  'BACHARELADO EM ENGENHARIA CIVIL',
  'BACHARELADO EM FARMÁCIA',
  'BACHARELADO EM FISIOTERAPIA',
  'BACHARELADO EM JORNALISMO',
  'BACHARELADO EM NUTRIÇÃO',
  'BACHARELADO EM SERVIÇO SOCIAL',
  'BACHARELADO EM TEOLOGIA',
  'BACHARELARO EM CIÊNCIAS ECONÔMICAS',
  'COMPLEMENTAÇÃO DE EDUCAÇÃO FÍSICA DE BAC. PARA LIC.',
  'COMPLEMENTAÇÃO DE EDUCAÇÃO FÍSICA DE LIC. PARA BAC',
  'COMPLEMENTAÇÃO DE EDUCAÇÃO FÍSICA DE LIC. PARA BAC.',
  'COMPLEMENTAÇÃO DE EDUCAÇÃO FÍSICA DE LIC. PARA BAC...',
  'COMPLEMENTAÇÃO DE EDUCAÇÃO FÍSICA LIC. PARA BAC.',
  'COMPLEMENTAÇÃO EM EDUCAÇÃO FÍSICA (LIC. PARA BAC.)',
  'COMPLEMENTAÇÃO EM EDUCAÇÃO FÍSICA DE LIC. PARA BAC.',
  'EDUCAÇÃO FÍSICA - BACHARELADO',
  'EDUCAÇÃO FÍSICA - LICENCIATURA',
  'FORMAÇÃO PEDAGÓGICA EM HISTÓRIA',
  'FORMAÇÃO PEDAGÓGICA EM PEDAGOGIA',
  'GESTÃO HOSPITALAR',
  'LETRAS',
  'LICENCIATURA EM BIOLOGIA',
  'LICENCIATURA EM EDUCAÇÃO FÍSICA',
  'LICENCIATURA EM HISTÓRIA',
  'LICENCIATURA EM LETRAS',
  'LICENCIATURA EM MATEMÁTICA',
  'LICENCIATURA EM PEDAGOGIA',
  'LICENCIATURAS EM LETRAS',
  'RECURSOS HUMANOS',
  'SEGUNDA GRADUAÇÃO EM EDUCAÇÃO FÍSICA BACHARELADO',
  'SEGUNDA GRADUAÇÃO EM EDUCAÇÃO FÍSICA LICENCIATURA',
  'SEGUNDA LICENCIATURA - HISTÓRIA',
  'SEGUNDA LICENCIATURA - PEDAGOGIA',
  'SEGUNDA LICENCIATURA EM HISTÓRIA',
  'SEGUNDA LICENCIATURA EM PEDAGOGIA',
  'SEGUNDA LICENCIATURA HISTÓRIA',
  'TECNOLOGIA DE GESTÃO EM SEGURANÇA PÚBLICA',
  'TECNOLOGIA EM DESIGN DE INTERIORES',
  'TECNOLOGIA EM ESTÉTICA E COSMETICA',
  'TECNOLOGIA EM GASTRONOMIA',
  'TECNOLOGIA EM GESTÃO AMBIENTAL',
  'TECNOLOGIA EM GESTÃO DA INFORMAÇÃO',
  'TECNOLOGIA EM GESTÃO DA TECNOLOGIA DA INFORMAÇÃO',
  'TECNOLOGIA EM GESTÃO DE SEGURANÇA PÚBLICA',
  'TECNOLOGIA EM GESTÃO EM SEGURANÇA PÚBLICA',
  'TECNOLOGIA EM GESTÃO FINANCEIRA',
  'TECNOLOGIA EM GESTÃO HOSPITALAR',
  'TECNOLOGIA EM GESTÃO PÚBLICA',
  'TECNOLOGIA EM JOGOS DIGITAIS',
  'TECNOLOGIA EM LOGÍSTICA',
  'TECNOLOGIA EM MARKETING',
  'TECNOLOGIA EM MODA',
  'TECNOLOGIA EM NEGÓCIOS IMOBILIÁRIOS',
  'TECNOLOGIA EM PROCESSOS GERENCIAIS',
  'TECNOLOGIA EM PRODUÇÃO PUBLICITÁRIA',
  'TECNOLOGIA EM PUBLICIDADE',
  'TECNOLOGIA EM RECURSOS HUMANOS',
  'TECNOLOGIA EM SEGURANÇA PÚBLICA',
  'TECNOLÓGICO EM GESTÃO AMBIENTAL',
  'TECNOLÓGICO EM GESTÃO DA TECNOLOGIA DA INFORMAÇÃO',
  'TECNOLÓGICO EM GESTÃO FINANCEIRA',
  'TECNOLÓGICO EM GESTÃO PÚBLICA',
  'TECNOLÓGICO EM MARKETING',
  'TECNOLÓGICO EM MODA',
  'TECNOLÓGICO EM RECURSOS HUMANOS'
];
export const POLOS_UNINTA = [
  'POLO ABAETETUBA-PA',
  'POLO ACARAÚ-CE (DESCREDENCIADO)',
  'POLO ACOPIARA-CE',
  'POLO ALENQUER-PA',
  'POLO ALTO ALEGRE-MA',
  'POLO ALTOS-PI',
  'POLO AMONTADA-CE',
  'POLO ANTAS-BA',
  'POLO ANTONINA DO NORTE-CE',
  'POLO APARECIDA DE GOIÂNIA-GO',
  'POLO APODI - RN',
  'POLO APODI-RN',
  'POLO ARACAJÚ - JABOTIANA (Salgado Filho)-SE',
  'POLO ARACAJU (JABOTIANA)-SE',
  'POLO ARACAJÚ-SE',
  'POLO ARACATI-CE',
  'POLO ARAGUAÍNA-TO',
  'POLO ARAPIRACA-AL',
  'POLO ARAPIRACA-AL(DESCREDENCIADO)',
  'POLO ARARIPINA-PE',
  'POLO AREIA-PB',
  'POLO ASSUNÇÃO-PB',
  'POLO AURORA-CE',
  'POLO BAIXO GUANDU-ES',
  'POLO BALSAS-MA',
  'POLO BARRA DO ROCHA-BA',
  'POLO BELÉM-PA (SACRAMENTO)',
  'POLO BOM CONSELHO-PE',
  'POLO BRASÍLIA-DF (PLANALTINA)',
  'POLO BURITI-MA',
  'POLO CABEDELO-PB',
  'POLO CACIMBINHAS-AL',
  'POLO CAICÓ - RN',
  'POLO CAICÓ - RN (SEMIPRESENCIAL)',
  'POLO CAICÓ-RN',
  'POLO CAMARAGIBE-PE',
  'POLO CAMOCIM-CE',
  'POLO CAMPESTRE-MA',
  'POLO CAMPINA GRANDE - PB (CENTRO)',
  'POLO CAMPINA GRANDE-PB',
  'POLO CAMPO MAIOR-PI',
  'POLO CAMPOS DOS GOYTACAZES-RJ (DESCREDENCIADO)',
  'POLO CÂNDIDO MENDES-MA',
  'POLO CANINDÉ-CE',
  'POLO CARUARÚ-PE',
  'POLO CASTANHAL-PA',
  'POLO CASTANHAL-PA (Maracanã)',
  'POLO CASTANHAL-PA (Tracuateua)',
  'POLO CASTELO DO PIAUÍ-PI',
  'POLO CEARÁ MIRIM - RN',
  'POLO CEARÁ MIRIM-RN',
  'POLO CEDRO-CE (DESCREDENCIADO)',
  'POLO CIPÓ-BA',
  'POLO CODÓ-MA',
  'POLO CONCEIÇÃO DA FEIRA-BA',
  'POLO CONFRESA-MT',
  'POLO CONTAGEM-MG',
  'POLO CORRENTE-PI (DESCREDENCIADO)',
  'POLO CRATO-CE',
  'POLO CROATÁ-CE',
  'POLO CUIABÁ (CAMPOS ELÍSEOS)-MT',
  'POLO CURIONÓPOLIS-PA',
  'POLO CURRAIS NOVOS-RN',
  'POLO DIADEMA-SP',
  'POLO DIADEMA-SP (SUPERVISÃO)',
  'POLO DIGITAL - BRASIL',
  'POLO DIGITAL - MUNDO',
  'POLO ESPERANÇA-PB',
  'POLO EUNÁPOLIS (IBICARAÍ) BA',
  'POLO EUNÁPOLIS-BA',
  'POLO EUSÉBIO-CE (DESCREDENCIADO)',
  'POLO FELIZ NATAL- MT',
  'POLO FELIZ NATAL- MT (DESCREDENCIADO)',
  'POLO FLORIANÓPOLIS-SC (DESCREDENCIADO)',
  'POLO FORMOSA DA SERRA NEGRA-MA',
  'POLO FORTALEZA - CE (JOÃO XXIII)',
  'POLO FORTALEZA (ANTONIO BEZERRA)-CE',
  'POLO FORTALEZA (MONTESE)-CE',
  'POLO FORTALEZA-CE (MARAPONGA)',
  'POLO FORTALEZA-CE (MEIRELES - DELTA)',
  'POLO FUNDÃO-ES',
  'POLO GUAÇUÍ (Fundão)-ES',
  'POLO GUACUÍ (João Neiva)-ES',
  'POLO GUAÇUÍ-ES',
  'POLO GUANAMBI (SANTO ANTÔNIO) -BA',
  'POLO IBICARAÍ (Simões Filho)-BA',
  'POLO IBICARAÍ-BA',
  'POLO IGARAPÉ-AÇU – PA',
  'POLO IGREJA NOVA-AL',
  'POLO IMPERATRIZ-MA',
  'POLO INDEPENDÊNCIA-CE',
  'POLO IPATINGA-MG',
  'POLO IPU-CE',
  'POLO IRACEMA-CE (DESCREDENCIADO)',
  'POLO IRECÊ-BA',
  'POLO ITABIRA-MG',
  'POLO ITAITUBA-PA',
  'POLO ITAMARAJU-BA',
  'POLO ITAPAJÉ-CE (DESCREDENCIADO)',
  'POLO ITAPIPOCA (São Gonçalo)-CE',
  'POLO ITAPIPOCA-CE',
  'POLO ITIRUÇU-BA',
  'POLO JACARAÚ-PB',
  'POLO JOÃO CÂMARA-RN',
  'POLO JOÃO NEIVA-ES',
  'POLO JOÃO PESSOA (BRISAMAR)-PB',
  'POLO JOÃO PESSOA (CENTRO) - PB',
  'POLO JOSE DE FREITAS–PI',
  'POLO JUAZEIRO (CENTRO)-BA',
  'POLO JUAZEIRO (IRECÊ)',
  'POLO JUAZEIRO DO NORTE (CENTRO)-CE',
  'POLO JURUTI-PA',
  'POLO LAGO DA PEDRA - MA',
  'POLO LIMEIRA - SP',
  'POLO LUIS CORREIA-PI',
  'POLO LUIS EDUARDO MAGALHÃES-BA',
  'POLO LUZILÂNDIA-PI',
  'POLO MACAU-RN',
  'POLO MÃE DO RIO-PA',
  'POLO MAJOR IZIDORO-AL',
  'POLO MANAUS (CENTRO)-AM',
  'POLO MANAUS (CIDADE NOVA) - AM',
  'POLO MANAUS-AM (CIDADE NOVA 2 - RUA 27) (SUPERVISÃO)',
  'POLO MARACANÃ-PA',
  'POLO MARINGÁ-PR',
  'POLO MATRIZ DE CAMARAGIBE-AL',
  'POLO MAUÉS-AM',
  'POLO MAURITI-CE',
  'POLO MILHÃ-CE',
  'POLO MIRANDA DO NORTE-MA',
  'POLO MONSENHOR HIPÓLITO - PI',
  'POLO MONSENHOR TABOSA-CE',
  'POLO MONTE ALEGRE DO PIAUÍ-PI',
  'POLO MOSQUEIRO-PA',
  'POLO MOSSORÓ-RN (DESCREDENCIADO)',
  'POLO NATAL (BOM PASTOR)-RN (DESCREDENCIADO)',
  'POLO NATAL (LAGOA NOVA)-RN',
  'POLO NOVA RUSSAS-CE',
  'POLO ORLANDO - FLÓRIDA - US',
  'POLO OURO BRANCO-AL',
  'POLO PACAJUS-CE',
  'POLO PALMAS-TO',
  'POLO PALMEIRA DOS ÍNDIOS-AL - (DESCREDENCIADO)',
  'POLO PARAGOMINAS (São Miguel do Guamá)-PA',
  'POLO PARAGOMINAS-PA',
  'POLO PARAGOMINAS-PA (Igarapé-Açu)',
  'POLO PARAGOMINAS-PA (Mãe do Rio)',
  'POLO PARNAIBA-PI',
  'POLO PARNAMIRIM - RN',
  'POLO PARNAMIRIM-RN',
  'POLO PARNAMIRIM-RN (Patú)',
  'POLO PATOS-PB (DESCREDENCIADO)',
  'POLO PATÚ-RN',
  'POLO PAULINO NEVES-MA',
  'POLO PAULISTANA-PI',
  'POLO PAULISTANA-PI (SEMIPRESENCIAL)',
  'POLO PAULO AFONSO - BA (SUPERVISÃO)',
  'POLO PEDRA BRANCA-CE',
  'POLO PEDREIRAS-MA',
  'POLO PERITORÓ-MA',
  'POLO PICOS - JABOTIANA (Canto da Várzea)-PI',
  'POLO PICOS (Catavento) (Promorar)-PI',
  'POLO PICOS (CATAVENTO)-PI',
  'POLO PICOS-PI',
  'POLO PICOS-PI (CATAVENTO) (SEMIPRESENCIAL)',
  'POLO PINHEIRO-MA',
  'POLO PIO XII-MA',
  'POLO PIRACURUCA-PI',
  'POLO PIRAPORA-MG',
  'POLO PORTO ALEGRE (CENTRO)-RS',
  'POLO PORTO ALEGRE (CENTRO)-RS (SUPERVISÃO)',
  'POLO PORTO DA FOLHA-SE (DESCREDENCIADO)',
  'POLO PORTO FRANCO-MA',
  'POLO PORTO SEGURO-BA',
  'POLO PORTO-PI (DESCREDENCIADO)',
  'POLO QUITERIANÓPOLIS-CE',
  'POLO QUIXADÁ-CE',
  'POLO RECIFE (BOA VIAGEM) - PE',
  'POLO REDENÇÃO-PA (DESCREDENCIADO)',
  'POLO RESPLENDOR-MG',
  'POLO RIO DE JANEIRO-RJ (SÃO CRISTÓVÃO)',
  'POLO RUSSAS-CE',
  'POLO SALINÓPOLIS-PA',
  'POLO SALVADOR (MANDU)- BA',
  'POLO SALVADOR (MANDU)- BA (DESCREDENCIADO)',
  'POLO SALVADOR (MANDU)- BA (SUPERVISÃO)',
  'POLO SANTA INÊS-MA',
  'POLO SANTA LUZIA - MA',
  'POLO SANTA LUZIA DO PARUÁ-MA',
  'POLO SANTANA DO IPANEMA-AL',
  'POLO SANTANA DO IPANEMA-AL (São José da Tapera)',
  'POLO SANTANA DO IPANEMA-AL (SEMIPRESENCIAL)',
  'POLO SANTO ANTONIO DO SALTO DA ONÇA - RN',
  'POLO SANTO ANTONIO DO SALTO DA ONÇA-RN',
  'POLO SÃO BENTO-PB',
  'POLO SÃO CRISTÓVÃO-SE',
  'POLO SÃO DESIDÉRIO-BA',
  'POLO SÃO GONÇALO DO AMARANTE-CE',
  'POLO SÃO JOÃO DO PIAUÍ-PI',
  'POLO SÃO JOÃO DOS PATOS-MA',
  'POLO SÃO JOSÉ DA TAPERA-AL',
  'POLO SÃO JOSÉ DA TAPERA-AL (SUPERVISÃO)',
  'POLO SÃO JOSÉ DE UBÁ-RJ',
  'POLO SÃO JOSÉ DO CAMPOS - SP',
  'POLO SÃO LUIS (COHAMA)-MA',
  'POLO SÃO LUIS (COHAMA)-MA (POLO DESCREDENCIADO)',
  'POLO SÃO MIGUEL DO GUAMÁ-PA',
  'POLO SÃO PAULO (SANTO AMARO)-SP',
  'POLO SÃO PAULO (VILA MARIANA)',
  'POLO SOBRAL (CENTRO) - CE (SEMIPRESENCIAL)',
  'POLO SOBRAL (JORDÃO) -CE',
  'POLO SOBRAL II - CE',
  'POLO SOBRAL- CENTRO',
  'POLO SOBRAL-CE',
  'POLO SOLÂNEA (Areia)-PB',
  'POLO SOLÂNEA-PB',
  'POLO SURUBIM-PE',
  'POLO TABOCAS DO BREJO VELHO-BA',
  'POLO TAMANDARÉ-PE',
  'POLO TAPEROÁ-PB',
  'POLO TAUÁ-CE',
  'POLO TERESINA - PI (LOURIVAL PARENTE)',
  'POLO TERESINA (PIO XII) (Promorar)-PI',
  'POLO TERESINA (PIO XII)-PI',
  'POLO TERESINA (PIO XII)-PI (SEMIPRESENCIAL)',
  'POLO TERESINA (PROMORAR)-PI',
  'POLO TERESINA-PI (DIRCEU)',
  'POLO TIANGUÁ-CE',
  'POLO TIMON-MA',
  'POLO TRACUATEUA-PA',
  'POLO TRACUATEUA-PA (SUPERVISÃO)',
  'POLO UBERABA - MG',
  'POLO UIRAUNA-PB',
  'POLO UNIÃO DOS PALMARES-AL',
  'POLO URUCARÁ-AM',
  'POLO VALENÇA- PI',
  'POLO VARJOTA-CE',
  'POLO VÁRZEA ALEGRE-CE',
  'POLO VIANA-MA',
  'POLO VIÇOSA (Arapiraca)-AL',
  'POLO VIÇOSA (União dos Palmares)-AL',
  'POLO VIÇOSA-AL',
  'POLO VITÓRIA DA CONQUISTA-BA',
  'POLO ZÉ DOCA - MA',
];

export const PERIODOS_EAD = [
  '2015.1',
  '2015.2',
  '2016.1',
  '2016.2',
  '2017.1',
  '2017.2',
  '2018.1',
  '2018.2',
  '2019.1',
  '2019.2',
  '2020.1',
  '2020.2',
  '2021.1',
];
