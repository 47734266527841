import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import { Dashboard as DashboardView, NotFound as NotFoundView } from './views';
import {
  Curso,
  CursoDisciplina,
  RankingPolos,
  RankingCursos,
  Login
} from './views/index';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/login"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/geral"
      />
      <RouteWithLayout
        component={Curso}
        exact
        layout={MainLayout}
        path="/curso"
      />
      <RouteWithLayout
        component={Login}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={CursoDisciplina}
        exact
        layout={MainLayout}
        path="/curs-disc"
      />

      <RouteWithLayout
        component={RankingPolos}
        exact
        layout={MainLayout}
        path="/ranking-polos"
      />

      <RouteWithLayout
        component={RankingCursos}
        exact
        layout={MainLayout}
        path="/ranking-cursos"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
