import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import palette from '../../.././../theme/palette';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));
const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: true },
  cornerRadius: 20,
  tooltips: {
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
const LatestSales = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [stateSelect, setStateSelect] = useState('geral');
  const handleChangeSelect = event => {
    const value = event.target.value;
    setStateSelect(value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={props.action} title={rest.tableTitle} />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Line data={props.data} options={options} redraw />
        </div>
      </CardContent>
      <CardActions className={classes.actions} />
    </Card>
  );
};

LatestSales.propTypes = {
  action: PropTypes.any,
  className: PropTypes.string,
  data: PropTypes.object
};

export default LatestSales;
