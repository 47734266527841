import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, ListSubheader } from '@material-ui/core';
import palette from '../.././theme/palette';
import {
  LatestSales,
  UsersByDevice
  // LatestOrders
} from './components';
import api from './../../services';
import { PERIODOS_EAD, POLOS_UNINTA } from '../../variable/index';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  selectStyle: {
    height: '4em',
    width: '20em'
  },
  selectInChartStyle: {
    height: '2em',
    width: '10em'
  },
  formControl: { marginLeft: 12 },
  teste: { alignItems: 'center', backgroundColor: '#ff0' }
}));
const dataDognout = dados => {
  return {
    datasets: [
      {
        data: [dados.aprovados, dados.reprovados],
        backgroundColor: [palette.success.main, '#dc3545'],
        borderWidth: 8,
        borderColor: palette.white,
        hoverBorderColor: palette.white
      }
    ],
    labels: ['Aprovados %', 'Reprovados %']
  };
};
const dataGeral = (
  dados,
  titulo = '',
  color = palette.primary.main,
  labels = PERIODOS_EAD
) => {
  return {
    labels,
    datasets: [
      {
        label: titulo,
        backgroundColor: 'transparent',
        borderColor: color,
        data: dados
      }
    ]
  };
};
const Dashboard = ({ history }) => {
  const classes = useStyles();
  const [stateSelect, setStateSelect] = useState('geral');
  const [stateData, setStateData] = useState({
    dados: [],
    labels: [],
    percentDados: [],
    percentLabels: [],
    aprov: 0,
    reprov: 0,
    grafico: {}
  });

  useEffect(() => {
    if (!sessionStorage.getItem('@aprovacoes/logged')) {
      history.push('/login');
    }
  }, []);

  const handleChangeSelect = async event => {
    event.preventDefault();
    const value = event.target.value;
    setStateSelect(value);

    //se seleciona polo
    if (value && value !== 'geral') {
      const { data } = await api.post('/media-polo', { polo: value });
      const percentData = await api.post('/percent-polo', { polo: value });

      const { dados, labels, percentDados, percentLabels } = stateData;
      dados.length = 0;
      labels.length = 0;
      percentDados.length = 0;
      percentLabels.length = 0;
      data.map(item => {
        dados.push(item.media.toFixed(2));
        labels.push(item.periodo);
      });
      percentData.data.map(item => {
        percentDados.push(item.porcentos.toFixed(2));
        percentLabels.push(item.periodo);
      });

      setStateData({ ...stateData, dados, labels, percentData, percentLabels });
    } else if (value === 'geral') {
      handleMediaGeral().then(ret => {
        setStateData({ ...stateData, ...ret });
      });
      handlePercentGeral().then(ret => {
        setStateData({ ...stateData, ...ret });
      });
    }
  };
  const handleMediaGeral = async () => {
    const { data } = await api.post('/media-geral');

    const { dados, labels } = stateData;
    dados.length = 0;
    labels.length = 0;
    data.map(item => {
      console.log({ item: typeof item.media });
      dados.push(new Number(item.media).toFixed(2));
      labels.push(item.periodo);
    });
    return { ...stateData, dados, labels };
  };
  const handlePercentGeral = async () => {
    const { data } = await api.post('/percent-geral');

    let totals = 0;
    let { percentDados, percentLabels, aprov, reprov } = stateData;
    percentDados.length = 0;
    percentLabels.length = 0;
    data.map(item => {
      let { total, passou } = item;
      percentDados.push(new Number(item.porcentos).toFixed(2));
      percentLabels.push(item.periodo);
      if (total && passou) {
        console.log(item.passou);
        aprov += +item.passou;
        reprov += +item.total - +item.passou;
        totals += +item.total;
      }
    });
    const pAprov = (100 * aprov) / totals;
    const pReprov = (100 * reprov) / totals;
    // console.log({ pAprov, pReprov });
    // aprov = 100*aprov/total;

    // reprov = 100*reprov/total;
    return {
      percentDados,
      percentLabels /* aprov: pAprov.toFixed(2), reprov: pReprov.toFixed(2), grafico*/
    };
  };
  useEffect(() => {
    handleMediaGeral().then(ret => {
      setStateData({ ...stateData, ...ret });
    });
    handlePercentGeral().then(ret => {
      setStateData({ ...stateData, ...ret });
    });
  }, []);
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Select
            className={classes.selectStyle}
            onChange={handleChangeSelect}
            value={stateSelect}
            variant="outlined">
            <MenuItem value="geral">Geral</MenuItem>
            <ListSubheader disableSticky>#Polos</ListSubheader>
            {POLOS_UNINTA.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {stateSelect === 'geral' && (
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <UsersByDevice
              dados={dataDognout({
                aprovados: (87.67438656876676).toFixed(2),
                reprovados: (12.32561343123324).toFixed(2)
              })}
              pAprov={(87.67438656876676).toFixed(2)}
              pReprov={(12.32561343123324).toFixed(2)}
            />
          </Grid>
        )}

        <Grid item lg={6} md={6} xl={6} xs={12}>
          <LatestSales
            data={dataGeral(
              stateData.dados,
              'Media de Aprovados',
              undefined,
              stateData.labels
            )}
            tableTitle="Media de Aprovados - G1-B"
          />
        </Grid>

        <Grid item lg={6} md={6} xl={6} xs={12}>
          <LatestSales
            data={dataGeral(
              stateData.percentDados,
              '% aprovados',
              palette.secondary.main,
              stateData.percentLabels
            )}
            tableTitle="Porcentagem de aprovados G1-A"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
