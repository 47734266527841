import { Button, Card, TextField } from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import imageAnimation from './../../assets/lottie/animationLogin.json';
import { apiLogin } from './../../services/';
import palette from './../../theme/palette';

export default function Login({ history }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({ usuario: '', senha: '' });
  useEffect(() => {
    if (sessionStorage.getItem('@aprovacoes/logged')) {
      history.push('/geral');
    }
  }, []);
  const handleLogin = async () => {
    const { usuario, senha } = state;

    if (usuario && senha) {
      await apiLogin
        .post('/authen', { userName: usuario, password: senha })
        .then(e => {
          console.log(e.data);
          if (e.data) {
            sessionStorage.setItem('@aprovacoes/logged', e.data);
            history.push('/geral');
          } else {
            showMensage('Usuário e senha incorretos!', 'error');
          }
        })
        .catch(e => {
          showMensage('Usuário e senha incorretos!', 'error');
        });
    } else {
      showMensage('Preencha corretamente os campos!', 'error');
    }
  };
  const showMensage = (mensagem, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, { variant });
  };

  return (
    <main
      style={{
        display: 'flex',
        height: '100%',
        flexGrow: 1,
        justifyContent: 'flex-end'
      }}>
      <figure
        style={{
          flexGrow: 1,
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}>
        <header>
          <h1
            style={{
              fontFamily: 'Arial, sans-serif',
              textAlign: 'center',
              marginTop: 16,
              fontWeight: 'normal',
              fontSize: 28,
              fontFamily: 'Roboto'
            }}>
            <span
              style={{
                color: palette.yellowColor.dark,
                fontWeight: 'bold',
                fontSize: 42,
                fontFamily: 'Jura'
              }}>
              Analytic
            </span>{' '}
            <br /> Rendimento Acadêmico
          </h1>
        </header>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: imageAnimation
          }}
          style={{}}
          width="50vw"
        />
        <footer style={{ marginBottom: 16, fontFamily: 'Arial' }}>
          <small>
            © DIRIN - PROEAD -{' '}
            <b style={{ color: palette.primary.dark }}>UNINTA</b>
          </small>
        </footer>
      </figure>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around'
        }}>
        <h1
          style={{
            fontFamily: 'sans-serif',
            paddingTop: 16,
            paddingLeft: 16,
            textAlign: 'center'
          }}>
          Login
        </h1>
        <Card
          style={{
            margin: 8,
            paddingTop: 20,
            paddingBottom: 22
          }}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: 16,
              justifyContent: 'center'
            }}>
            <TextField
              id="user"
              label="Usuário"
              onChange={e => {
                const value = e.target.value;
                setState({ ...state, usuario: value });
              }}
            />
            <TextField
              id="password"
              label="Senha"
              type="password"
              onChange={e => {
                const value = e.target.value;
                setState({ ...state, senha: value });
              }}
            />
            <Button
              color="primary"
              style={{ marginTop: 16 }}
              onClick={e => {
                e.preventDefault();
                handleLogin();
              }}
              variant="contained">
              Entrar
            </Button>
          </form>
        </Card>
        .
      </div>
    </main>
  );
}
